import "../../css/learnMore.css";

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CtaButton } from "./ctaButton";
import { TAB_PROVIDERS } from "./insurancePage";
import { useTabChanger } from "../services/hooks";

import Section from "./section";

export const LearnMore = () => {
  const changeTab = useTabChanger();
  return (
    <Section className="learn-more">
      <Box className="wrapper">
        <Typography variant="h3">
          Want to learn more about pet insurance offers?
        </Typography>
        <CtaButton
          onClick={() => {
            changeTab(TAB_PROVIDERS);
          }}
        >
          Explore insurance plans
        </CtaButton>
      </Box>
    </Section>
  );
};

export default LearnMore;
