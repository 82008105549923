import "../../css/planSummaries.css";

import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Section from "./section";
import PlanSummaryFactory, {
  PROVIDER_PUMPKIN,
  PROVIDER_TRUPANION,
  PROVIDER_NATIONWIDE,
} from "./planSummary";

const PlanSummaries = () => (
  <Section className="plan-summaries">
    <Typography variant="h2" className="desktop-only">
      Pet insurance providers
    </Typography>
    <Typography variant="h5" className="desktop-only subtitle">
      Explore these excellent providers to find the right coverage for your dog.
    </Typography>
    <img
      src={`${process.env.PUBLIC_URL}/img/epis_logo.svg`}
      alt="embark logo"
      className="intro-logo mobile-only"
    />

    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={useMediaQuery(useTheme().breakpoints.down("md")) ? 0 : 4}
      className="provider-cards"
    >
      {[PROVIDER_PUMPKIN, PROVIDER_TRUPANION, PROVIDER_NATIONWIDE].map(
        (provider) => (
          <Grid item xs={12} md={4} key={provider}>
            <PlanSummaryFactory provider={provider} />
          </Grid>
        )
      )}
    </Grid>
  </Section>
);

export default PlanSummaries;
