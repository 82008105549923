import React from "react";

export const DoubleDownCaret = React.forwardRef((props, ref) => (
  <svg
    ref={ref}
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 1L6 6L11 1" stroke="#004E6F" strokeWidth="1.5" />
    <path d="M1 7.5L6 12.5L11 7.5" stroke="#004E6F" strokeWidth="1.5" />
  </svg>
));

export const ExpandMore = React.forwardRef((props, ref) => (
  <svg
    ref={ref}
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.855 0.0216675L7.5 5.365L2.145 0.0216675L0.5 1.66667L7.5 8.66667L14.5 1.66667L12.855 0.0216675Z"
      fill="#004E6F"
    />
  </svg>
));

export const CheckMarkCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
    fill="none"
    className="icon"
  >
    <g clipPath="url(#clip0_80_2984)">
      <path
        d="M14 5.70955e-05C21.732 5.70955e-05 28 6.26808 28 14C28 21.732 21.732 28 14 28C6.26807 28 5.80701e-05 21.732 5.80701e-05 14C-0.0219427 6.29 6.21037 0.0220579 13.9203 5.70955e-05C13.9469 -1.90318e-05 13.9734 -1.90318e-05 14 5.70955e-05Z"
        fill="#3BB54A"
      />
      <path
        d="M21.7555 10.1421L11.8123 20.0852L6.24414 14.5568L8.51121 12.3296L11.8123 15.5909L19.4885 7.91479L21.7555 10.1421Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_80_2984">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const XCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
    fill="none"
    className="icon"
  >
    <path
      d="M14 27.9816C21.732 27.9816 28 21.7177 28 13.9908C28 6.2639 21.732 0 14 0C6.26801 0 0 6.2639 0 13.9908C0 21.7177 6.26801 27.9816 14 27.9816Z"
      fill="#E21B1B"
    />
    <path
      d="M9.29517 7.00073L7.00098 9.29492L18.7061 21L21.0003 18.7058L9.29517 7.00073Z"
      fill="white"
    />
    <path
      d="M18.7051 6.99999L7 18.7051L9.2942 20.9993L20.9993 9.29418L18.7051 6.99999Z"
      fill="white"
    />
  </svg>
);
