export const FAQData = [
  {
    question: "When should I get insurance for my dog?",
    answers: [
      "Now’s the time! It’s never too early to sign up for pet insurance. Many pet insurance plans have waiting periods for both accidents and illnesses. The sooner you sign up, the sooner your pup is covered.",
    ],
  },
  {
    question: "How much does pet insurance cost?",
    answers: [
      "The cost of pet insurance is typically based on many factors, including the zip code for where you live, the breed of dog, the age of the dog, the coverage options you select, chosen deductible, reimbursement rate, and the annual coverage limit. Online quotes are available from most pet insurance providers.",
      "Think pet insurance is too expensive? Ask yourself what you would do if you ended up with a $5,000 veterinary bill for an accident or illness.",
    ],
  },
  {
    question: "What are pre-existing conditions?",
    answers: [
      "Most of the time accidents or illnesses that occur before coverage starts or during the waiting period will be excluded from coverage. (i.e. if your dog has a condition diagnosis or shows symptoms of the condition prior to your coverage starting, the condition  will be excluded from coverage. . By contrast, new accidents or illnesses that are not considered pre-existing may be covered.",
    ],
  },
  {
    question:
      "What are deductibles, reimbursement rates, and annual coverage limits?",
    answers: [
      "When purchasing pet insurance, you typically have the option to choose from different plans with varying deductibles, reimbursement rates, and annual limits.",
      "A deductible is an amount that you will be required to pay out of pocket for eligible vet bills before the insurance coverage kicks in. It’s always important to determine if a deductible is per condition or per policy year. If the deductible is per condition and your pet becomes injured or ill three times in a year due to different conditions, you may end up paying the deductible three times. On the other hand, annual deductibles only have to be met once in a policy year.",
      "The reimbursement rate is the amount a pet insurance plan pays you back for covered vet bills. The most extensive pet insurance reimbursement rates are 80% to 100%.",
      "The annual coverage limit is the amount of money that the insurance plan will pay out for eligible conditions each year.",
      "The variations in these plan options  affect the price you pay for the insurance.   You should review your finances to determine what you can afford to spend in the event of an emergency. Then, choose a policy that fits your needs.",
    ],
  },
  {
    question: "How does pet insurance work?",
    answers: [
      "You will typically pay the cost of care up front and then submit a claim to the insurance company  for reimbursement. You will receive payment according to the plan terms outlined in the insurance plan you choose. The plan terms might include a reimbursement rate, deductible, and annual coverage limit.",
    ],
  },
  {
    question: "How do I know what my policy covers?",
    answers: [
      "Every insurance policy is subject to terms and conditions. It’s important to read your policy and to ask questions before signing up.",
      "Different states have different insurance laws and some benefits vary by plan, so coverage may differ.",
    ],
  },
  {
    question: "Does my vet accept pet insurance?",
    answers: [
      "You can generally take your pet to any licensed veterinarian in the United States for eligible care. Unlike a human health insurance HMO, most pet insurance plans aren’t limited to a “network” of health care providers.",
    ],
  },
  {
    question: "How does filing a claim work?",
    answers: [
      "Making a claim should be easy. You pay your veterinarian up front. Then, fill out a claim form and submit it with a copy of your invoice through the insurance provider’s website or mobile app. You can also send it via email. The claim paperwork will be reviewed by an adjuster. The adjuster may request additional information, such as medical records from your veterinarian.",
      "Once all the required documents have been received,  the eligible expenses for the claim will be paid. Payment may come via direct deposit or check, depending on the provider.",
    ],
  },
  {
    question: "Couldn’t I just set up a savings account for my pet?",
    answers: [
      "Setting up a savings account for unexpected veterinary costs is a wonderful idea. However, it can take years to create a sufficient “nest egg”. Then, dependent on the severity of the accident or illness, those savings couldbe wiped out completely.",
    ],
  },
  {
    question: "Does pet insurance cover routine vaccines?",
    answers: [
      "Some pet insurance plans do offer coverage for wellness and routine care benefits, such as routine vaccines. Others offer optional wellness packages that are not part of the base insurance plan, but an add-on benefit you can purchase with your insurance plan.",
    ],
  },
  {
    question: "Is my pet covered if we are traveling?",
    answers: [
      "Traveling with a pup can be great fun! If your pet gets sick or hurt while traveling in the United States, pet insurance will generally cover the eligible veterinary bills at US vets a lot of the time. Some insurance providers also offer coverage for Canadian vets and elsewhere. Review your policy document to determine which countries your pet has coverage in.",
    ],
  },
];

export default FAQData;
