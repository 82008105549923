import "../../css/planSummary.css";

import React from "react";
import Collapse from "@mui/material/Collapse";

import { useToggle } from "../services/hooks";
import { CtaButtonLink, PumpkinCtaButton } from "./ctaButton";
import { Heap } from "../services/heap";

const capitalizeFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const getPetName = () => {
  const url = new URL(window.location.href);
  const petName = url.searchParams.get("petName");
  if (petName && petName.length <= 20) {
    return capitalizeFirstLetter(petName);
  }
  return petName;
};

const petName = () => {
  return getPetName() ?? "your dog";
};

const PumkinSummaryCard = ({ ...props }) => (
  <PlanSummary
    recommended
    logo={
      <img
        src={`${process.env.PUBLIC_URL}/img/logo_pumpkin.svg`}
        alt="pumpkin logo"
        className="logo"
      />
    }
    intro={
      <span>
        Pumpkin's plans are very extensive They cover key things some other
        insurance plans leave out. Pumpkin Dog Insurance plans make it easier to
        say "yes" to the best veterinary care.
      </span>
    }
    bullets={
      <ul>
        <li>
          High 80% or 90% reimbursement rate on covered vet bills and flexible
          annual limit options based on your needs
        </li>
        <li>
          All plans cover hereditary and breed-specific accidents and illnesses,
          plus have no breed exclusions
        </li>
        <li>
          All plans cover behavioral issues, vet exam fees for accidents &
          illnesses, and alternative therapies and Rx food & supplements* used
          to treat covered conditions at no extra cost
        </li>
      </ul>
    }
    extra={
      <span>
        If your veterinarian uses Trupanion’s direct payment software, you don’t
        need to file a claim; Trupanion pays the vet directly. You as the pet
        parent then don’t need to worry about making upfront payments or
        submitting reimbursement claims, which are standard for most of the pet
        insurance industry.
      </span>
    }
    cta={
      <PumpkinCtaButton
        petName={petName()}
        additionalClasses="pumpkin-extra-spacing"
        data-heap-action={Heap.ACTIONS.PUMPKIN_QUOTE}
        data-heap-category={Heap.CATEGORIES.PET_INSURANCE}
      />
    }
    {...props}
  />
);

const TrupanionSummaryCard = (props) => (
  <PlanSummary
    logo={
      <img
        src={`${process.env.PUBLIC_URL}/img/logo_trupanion.svg`}
        alt="trupanion logo"
        className="logo"
      />
    }
    intro={
      <span>
        Trupanion offers relatively comprehensive pet insurance compared to
        other options. They are known for their software that allows some vets
        to be paid directly, making billing and reimbursement a snap.
      </span>
    }
    bullets={
      <ul>
        <li>
          High 90% reimbursement rate on covered vet bills, but only one
          unlimited annual limit option
        </li>
        <li>
          All plans cover hereditary and breed-specific conditions, plus have no
          breed exclusions
        </li>
        <li>Plans don't cover vet exam fees for accidents & illnesses</li>
        <li>
          Plans cover behavioral issues and alternative therapies for an extra
          monthly fee
        </li>
      </ul>
    }
    extra={
      <span>
        If your veterinarian uses Trupanion’s direct payment software, you don’t
        need to file a claim; Trupanion pays the vet directly. You as the pet
        parent then don’t need to worry about making upfront payments or
        submitting reimbursement claims, which are standard for most of the pet
        insurance industry.
      </span>
    }
    cta={
      <CtaButtonLink
        url="https://trupanion.com/enrollments/ppc-get-quote"
        id="explore-plans-trupanion-cta"
        additionalClasses="trupanion-extra-spacing"
        data-heap-action={Heap.ACTIONS.TRUPANION_QUOTE}
        data-heap-category={Heap.CATEGORIES.PET_INSURANCE}
      />
    }
    {...props}
  />
);

const NationwideSummaryCard = (props) => (
  <PlanSummary
    logo={
      <img
        src={`${process.env.PUBLIC_URL}/img/logo_nationwide.svg`}
        alt="trupanion logo"
        className="logo nationwide-logo"
      />
    }
    intro={
      <span>
        Nationwide is one of the largest pet insurers, and offers a variety of
        insurance and wellness plans. You may be able to save by bundling with
        your home or auto insurance.
      </span>
    }
    bullets={
      <ul>
        <li>
          Puts you in control by offering multiple plan options with varying
          degrees of reimbursement rates
        </li>
        <li>
          Choose whether to include benefits for treatments like vaccinations,
          blood tests, and alternative therapies
        </li>
        <li>
          Whole Pet plan offers a 50%-70% reimbursement rate on covered vet
          bills and flexible annual limit options. Major Medical plan covers a
          capped amount per condition, per year
        </li>
        <li>
          Nationwide plans do not have breed exclusions, but their Major Medical
          plan does not cover several hereditary and breed-specific conditions.
        </li>
      </ul>
    }
    extra={
      <span>
        Whole Pet plan offers a 50%-70% reimbursement rate on covered vet bills
        and flexible annual limit options. Major Medical plan covers a capped
        amount per condition, per year. Nationwide plans do not have breed
        exclusions, but their Major Medical plan does not cover several
        hereditary and breed-specific conditions. Major Medical plan does not
        cover behavioral issues or Rx food & supplements.
      </span>
    }
    cta={
      <CtaButtonLink
        url="https://qec.petinsurance.com/quote1"
        id="explore-plans-nationwide-cta"
        data-heap-action={Heap.ACTIONS.NATIONWIDE_QUOTE}
        data-heap-category={Heap.CATEGORIES.PET_INSURANCE}
      />
    }
    {...props}
  />
);

const PlanSummary = ({
  recommended,
  logo,
  intro,
  bullets,
  extra,
  cta,
  provider,
}) => {
  const [expanded] = useToggle();

  return (
    <div id={provider} className="plan-summary">
      <div className="plan-header">
        {recommended && <div className="plan-banner">RECOMMENDED</div>}
      </div>
      <div className="plan-content">
        <div>{logo}</div>
        <div className="intro">{intro}</div>
        <hr className="desktop-only" />
        <div className="bullets">{bullets}</div>
        <Collapse in={expanded}>
          <div className="extra">{extra}</div>
        </Collapse>
        {cta}
      </div>
    </div>
  );
};

export const PROVIDER_PUMPKIN = "pumpkin";
export const PROVIDER_TRUPANION = "trupanion";
export const PROVIDER_NATIONWIDE = "nationwide";

export const PlanSummaryFactory = ({ ...props }) => {
  switch (props.provider) {
    case PROVIDER_PUMPKIN:
      return <PumkinSummaryCard {...props} />;
    case PROVIDER_TRUPANION:
      return <TrupanionSummaryCard {...props} />;
    case PROVIDER_NATIONWIDE:
      return <NationwideSummaryCard {...props} />;
    default:
      return <></>;
  }
};

export default PlanSummaryFactory;
