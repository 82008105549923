import "../../css/howDoesItWork.css";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import Section from "./section";
import Typography from "@mui/material/Typography";

const INSURANCE_STEPS = [
  {
    step: 1,
    header: "How does pet insurance work?",
    text: "Take your pet to any licensed veterinarian for treatment and pay as usual when you leave.",
  },
  {
    step: 2,
    header: "Submit your claim",
    text: "Submit your insurance claim according to your plan’s process",
  },
  {
    step: 3,
    header: "Get reimbursed",
    text: "Based on your plan, you’ll get paid back for your eligible expenses after your deductible is met. ",
  },
];

const OutlinedStep = ({ step, header, text }) => (
  <Box className="how-to-step">
    <Typography className="number">{step}</Typography>
    <Box className="step-content">
      <Typography variant="subtitle2">{header}</Typography>
      <Typography className="text">{text}</Typography>
    </Box>
  </Box>
);

const HowDoesItWork = () => (
  <Section className="how-it-works-background">
    <Grid container className="how-it-works">
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle1">
          How does pet insurance work?
        </Typography>
        <Typography>
          Most pet insurance plans are based on a reimbursement model. You’ll
          purchase your coverage from a provider, pay a monthly premium, have
          the flexibility to see the licensed vet of your choice for treatment,
          and get paid back on eligible claims you submit.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography
          variant="subtitle1"
          className="using-your-insurance-header mobile-only"
        >
          Using your pet insurance
        </Typography>
        {INSURANCE_STEPS.map((step_props) => (
          <OutlinedStep key={step_props.step} {...step_props} />
        ))}
      </Grid>
    </Grid>
  </Section>
);

export default HowDoesItWork;
