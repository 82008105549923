import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const AnchorScrollHandler = ({ location }) => {
  useEffect(() => {
    // force scroll to occur after current render completes.
    setTimeout(() => {
      const element = document.getElementById(location.hash.replace("#", ""));
      const tabs_height = document.getElementById("tabs").offsetHeight;
      if (element) {
        const top = element.offsetTop - tabs_height;
        window.scrollTo({
          top,
          behavior: "smooth",
        });
      }
    });
  }, [location]);
  return <></>;
};

export default withRouter(AnchorScrollHandler);
