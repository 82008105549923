import "../../css/footer.css";
import "../../css/disclaimers.css";

import React from "react";
import Section from "./section";
import Typography from "@mui/material/Typography";
import { Config, getConfig } from "../services/config";
import { getCurrentYear } from "js/services/date";

const pumpkinDomain = getConfig(
  Config.PUMPKIN_BASE_URL,
  "https://embark.pumpkin.care"
);

const Footer = () => (
  <Section className="footer">
    <div className="links">
      <Typography variant="body1" component="p" className="disclaimer-spacing">
        *Prescription food & supplement coverage terms:
      </Typography>
      <Typography
        variant="body2"
        component="p"
        className="base-paragraph-font paragraph-spacing footer-hr"
      >
        Pumpkin insurance covers prescription food & supplements to treat an
        eligible accident or illness. It does not cover prescription food &
        supplements used for weight management or general health maintenance.
      </Typography>
      <Typography
        variant="body1"
        component="p"
        className="disclaimer-link-section"
      >
        *
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.businessinsider.com/personal-finance/best-pet-insurance"
          className="disclaimer-link"
        >
          Business Insider: The best pet insurance companies for your beloved
          cats, dogs, and more
        </a>
      </Typography>
      <Typography
        variant="body1"
        component="p"
        className="disclaimer-link-section"
      >
        *
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.akcpetinsurance.com/blog/the-cost-of-puppys-first-year"
          className="disclaimer-link"
        >
          AKC Pet Insurance: The Cost of Puppy's First Year
        </a>
      </Typography>
      <Typography
        variant="body1"
        component="p"
        className="disclaimer-link-section disclaimer-hr"
      >
        *
        <a
          target="_blank"
          rel="noreferrer"
          href="https://naphia.org/industry-data/section-3-average-premiums/"
          className="disclaimer-link"
        >
          NAPHIA: Average Annual Premium Year Over Year
        </a>
      </Typography>

      <hr />
      <Typography
        variant="body2"
        component="p"
        className="base-paragraph-font paragraph-spacing footer-section"
      >
        All decisions regarding any insurance products, including approval,
        premium, commissions and fees, will be made solely by Pumpkin Pet
        Insurance or the relevant insurance carrier.
      </Typography>
      <Typography
        variant="body2"
        component="p"
        className="base-paragraph-font paragraph-spacing footer-section"
      >
        All insurance products are governed by the terms, conditions,
        limitations and exclusions set forth in the applicable insurance policy.
      </Typography>
      <Typography
        variant="body2"
        component="p"
        className="base-paragraph-font paragraph-spacing footer-section disclaimer-hr"
      >
        Any information on the Site does not in any way alter, supplement, or
        amend the terms, conditions, limitations or exclusions of the applicable
        insurance policy and is intended only as a brief summary of such
        insurance product.
      </Typography>
      <hr />
      <Typography
        variant="body2"
        component="p"
        className="base-paragraph-font paragraph-spacing footer-section"
      >
        Pumpkin Pet Insurance policies do not cover pre-existing conditions.
        Waiting periods, annual deductible, co-insurance, benefit limits and
        exclusions may apply. For full terms, visit{" "}
        <a
          href={`${pumpkinDomain}/insurancepolicy`}
          className="disclaimer-link"
        >
          pumpkin.care/insurancepolicy
        </a>
        . Products and rates may vary and are subject to change. Discounts may
        vary and are subject to change.
      </Typography>
      <Typography
        variant="body2"
        component="p"
        className="base-paragraph-font paragraph-spacing footer-section"
      >
        Insurance products underwritten by Independence American Insurance
        Company (NAIC #2658; Scottsdale, AZ), or United States Fire Insurance
        Company (NAIC #21113; Morristown, NJ); and are administered & produced
        by Pumpkin Insurance Services Inc. (“Pumpkin”) (NPN #19084749; New York,
        NY; CA License #6001617). For more details visit{" "}
        <a
          href={`${pumpkinDomain}/underwriting-information`}
          className="disclaimer-link"
        >
          pumpkin.care/underwriting-information
        </a>{" "}
        and{" "}
        <a
          href={`${pumpkinDomain}/insurance-licenses`}
          className="disclaimer-link"
        >
          pumpkin.care/insurance-licenses
        </a>
        .
      </Typography>
      <Typography
        variant="body2"
        component="p"
        className="base-paragraph-font paragraph-spacing"
      >
        Pumpkin Preventive Essentials is not an insurance policy. It is offered
        as an optional add-on non-insurance benefit. Pumpkin is responsible for
        the product and administration. For full terms, visit{" "}
        <a
          href={`${pumpkinDomain}/customeragreement`}
          className="disclaimer-link"
        >
          pumpkin.care/customeragreement
        </a>
        . Pumpkin Preventive Essentials is not available in all states.
      </Typography>
    </div>
    <div className="logo">
      <img
        src={`${process.env.PUBLIC_URL}/img/embark-insurance-logo-white.svg`}
        alt="placeholder"
      />
      <div className="copy">© {getCurrentYear()}</div>
    </div>
  </Section>
);

export default Footer;
