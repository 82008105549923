import Container from "@mui/material/Container";
import FAQ from "./faq";
import Footer from "./footer";
import LearnMore from "./learnMore";
import OurPartners from "./tabs/ourPartners";
import PetInsurance from "./tabs/petInsurance101";
import React from "react";
import { useTabChanger } from "../services/hooks";
import { DisclaimersContainer } from "js/components/disclaimers/disclaimersContainer";
import NavBar from "./navigation";

export const TAB_PET_INSURANCE = "1";
export const TAB_PROVIDERS = "2";

const InsurancePage = ({ activeTab }) => {
  const changeTab = useTabChanger();

  return (
    <Container maxWidth="lg" disableGutters>
      <NavBar activeTab={activeTab} onTabChange={changeTab} />
      {activeTab === TAB_PET_INSURANCE && <PetInsurance />}
      {activeTab === TAB_PROVIDERS && <OurPartners />}
      <FAQ />
      <LearnMore />
      <DisclaimersContainer />
      <Footer />
    </Container>
  );
};

export default InsurancePage;
