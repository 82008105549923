import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import states from "js/services/data/licenseInfoStates";
import "css/disclaimers.css";

export const LicenseInfoModal = ({ isDisplayed = false, toggleModal }) => {
  const handleClose = () => {
    toggleModal();
  };

  return (
    <Dialog
      open={isDisplayed}
      onClose={handleClose}
      aria-labelledby="disclaimers-licenseInfoModal-title"
      aria-describedby="disclaimers-licenseInfoModal-description"
      maxWidth="md"
      scroll="paper"
    >
      <DialogTitle id="disclaimers-licenseInfoModal-title">
        Insurance Licenses
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          justifyContent={"space-between"}
          className="disclaimers-licenseInfoModal-grid"
        >
          <section className="disclaimers-licenseInfoModal-intro">
            <p>
              Insurance plans offered by Pumpkin Insurance Services Inc. are
              produced by Embark Pet Insurance Services Inc. (NPN 19993272).
            </p>
            <p className="disclaimers-licenseInfoModal-title">
              License Information:
            </p>
          </section>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              {states
                .filter((state) => {
                  return state.group === 1;
                })
                .map((state, index) => {
                  return (
                    <p
                      key={index}
                      className="disclaimers-licenseInfoModal-stateLine"
                    >
                      {state.name}: {state.id}
                    </p>
                  );
                })}
            </Grid>
            <Grid item xs={12} md={6}>
              {states
                .filter((state) => {
                  return state.group === 2;
                })
                .map((state, index) => {
                  return (
                    <p
                      key={index}
                      className="disclaimers-licenseInfoModal-stateLine"
                    >
                      {state.name}: {state.id}
                    </p>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
        <DialogContentText id="disclaimers-licenseInfoModal-description">
          Insurance products underwritten by Independence American Insurance
          Company (NAIC #2658; Scottsdale, AZ), or United States Fire Insurance
          Company (NAIC #21113; Morristown, NJ); and are administered & produced
          by Pumpkin Insurance Services Inc. (“Pumpkin”) (NPN #19084749; New
          York, NY; CA License #6001617).
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
