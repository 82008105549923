import "../../css/lifetimeCareCost.css";

import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Section from "./section";
import Image from "js/components/image";

const SIZE_COMPARE_DATA = [
  {
    size: "Small",
    img: "maltese-sitting.png",
    cost: "15,051",
    expectancy: 15,
  },
  {
    size: "Medium",
    img: "medium-sized-dog-sitting.png",
    cost: "15,782",
    expectancy: 13,
  },
  {
    size: "Large",
    img: "american-pitbull-sitting.png",
    cost: "14,480",
    expectancy: 10,
  },
];

const SizeCompareDetail = ({ size, img, cost, expectancy }) => (
  <>
    <Image
      className={`dog-${size.toLowerCase()}`}
      alt={`${size} dog`}
      src={`${process.env.PUBLIC_URL}/img/${img}`}
    />
    <hr className="desktop-only" />
    <Typography variant="subtitle2">{size} dogs</Typography>
    <Typography className="cost">
      ${cost}
      <span>*</span>
    </Typography>
    <Typography className="expectancy">
      life expectancy of {expectancy} years
    </Typography>
  </>
);

export const LifetimeCareCost = () => (
  <Section>
    <Grid container className="lifetime-care-cost">
      <Grid item className="intro" xs={12} md={6}>
        <Typography variant="subtitle1">
          The lifetime cost of pet care*
        </Typography>
        <Typography variant="body1" component="p">
          As much as we love our pets, keeping them happy and healthy can be
          expensive. Over the course of their lifetime, you can expect to spend
          thousands of dollars on food, toys, treats, grooming, and veterinary
          care.
        </Typography>
      </Grid>
      <Grid item className="info" xs={12} md={6}>
        <Grid container className="size-cost-compare outline">
          {SIZE_COMPARE_DATA.map((detail) => (
            <Grid key={detail.size} item xs={4} md={4}>
              <SizeCompareDetail {...detail} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item className="conclusion" xs={12} md={6}>
        <Typography variant="body1" component="p">
          With your Embark Breed & Health DNA Test Kit results, you can factor
          in breed-specific health risks or hereditary conditions that can
          significantly increase the cost of pet ownership over their lifetime.
        </Typography>
      </Grid>
    </Grid>
  </Section>
);

export default LifetimeCareCost;
