import "../../css/topReasons.css";

import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Section from "./section";

const TopReasons = () => {
  const topReasons = [
    "Help getting peace of mind",
    "Help affording future, potentially large eligible vet bills",
    "Help ensuring the ability to always give their pets the best care",
    "Get reimbursed for eligible vet bills",
  ];

  const thingsInMind = [
    "1 in 3 dogs need emergency treatment every year; accidents and illnesses can happen at any time*",
    "The cost of vet care is on the rise with advancements in diagnostics and treatments",
    "Illnesses can happen at any time, and the sooner you get pet insurance, the sooner you have help covering eligible illnesses",
    "Dedicated pet care savings accounts can take years to build up, but can be gone with just one incident",
  ];

  const topReasonsComponent = topReasons.map((topReason, index) => {
    return (
      <Grid
        container
        spacing={1}
        key={index}
        className="topReasons-container padding-top-small"
      >
        <Grid item xs={2} className="cardDropdownOption-imgContainer">
          <img
            src={`${process.env.PUBLIC_URL}/img/check-dark-blue.svg`}
            alt="placeholder"
          />
        </Grid>
        <Grid item xs={10} className="cardDropdownOption-textContainer">
          <Typography
            variant="subtitle1"
            className="cardDropdownOption-text aboutPetInsurance-topReasons-item"
          >
            {topReason}
          </Typography>
        </Grid>
      </Grid>
    );
  });

  const thingsToKeepInMind = thingsInMind.map((item, index) => {
    return (
      <Grid
        container
        key={index}
        className="topReasons-container padding-top-medium"
      >
        <Grid item xs={1} className="cardDropdownOption-imgContainer">
          <div className="aboutPetInsurance-thingsToKeepInMind-circle" />
        </Grid>
        <Grid item xs={11} className="cardDropdownOption-textContainer">
          <Typography
            variant="subtitle1"
            className="cardDropdownOption-text aboutPetInsurance-topReasons-item"
          >
            {item}
          </Typography>
        </Grid>
      </Grid>
    );
  });

  return (
    <Section className="top-reasons">
      <Grid container className="top-reasons-container">
        <Grid
          item
          xs={12}
          md={6}
          className="aboutPetInsurance-topReasons-item1"
        >
          <Typography
            variant="subtitle1"
            className="padding-top-medium aboutPetInsurance-topReasons-header"
          >
            Top reasons why Embark customers get pet insurance
          </Typography>
          {topReasonsComponent}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="aboutPetInsurance-thingsToKeepInMind-item2"
        >
          <section className="aboutPetInsurance-thingsToKeepInMind-chart">
            <Typography
              variant="subtitle1"
              className="padding-top-medium aboutPetInsurance-thingsToKeepInMind-header"
            >
              Things to keep in mind while deciding
            </Typography>
            {thingsToKeepInMind}
          </section>
        </Grid>
      </Grid>
    </Section>
  );
};

export default TopReasons;
