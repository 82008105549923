import React from "react";

import { Transition } from "react-transition-group";

const AnimateTransition = ({
  in: active = false,
  timeout = 300,
  enter = {},
  exit = {},
  transition,
  children,
}) => {
  const ref = React.useRef(null);
  const style = {
    transition,
    ...(active ? enter : exit),
  };
  return (
    <Transition in={active} timeout={timeout} nodeRef={ref}>
      <>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, { ref, style })
        )}
      </>
    </Transition>
  );
};

const AnimateProperty = ({
  property = "all",
  timeout = 300,
  timing = "ease-in-out",
  delay = 0,
  ...props
}) => (
  <AnimateTransition
    timeout={timeout}
    transition={`${property} ${timeout}ms ${timing} ${delay}ms`}
    {...props}
  />
);

export const Rotate180 = (props) => (
  <AnimateProperty
    {...props}
    property="transform"
    enter={{ transform: "rotate(180deg)" }}
  />
);

export const FlipY = (props) => (
  <AnimateProperty
    {...props}
    property="transform"
    enter={{ transform: "scaleY(-1)" }}
  />
);

export const Pad = ({ padding, ...props }) => (
  <AnimateProperty {...props} property="padding" enter={{ padding }} />
);
