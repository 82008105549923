// A data structure that holds Pumpkin's ids for states they are licensed in
// The group refers to the column in which a state will be displayed in the grid
const states = [
  {
    name: "Alabama",
    id: "3001420546",
    group: 1,
  },
  {
    name: "Alaska",
    id: "3001512749",
    group: 1,
  },
  {
    name: "Arizona",
    id: "3001420537",
    group: 1,
  },

  {
    name: "Arkansas",
    id: "3001420535",
    group: 1,
  },
  {
    name: "Colorado",
    id: "702934",
    group: 1,
  },
  {
    name: "Connecticut",
    id: "002735215",
    group: 1,
  },
  {
    name: "Delaware",
    id: "3001420558",
    group: 1,
  },
  {
    name: "Dist. of Columbia",
    id: "3001420539",
    group: 1,
  },
  {
    name: "Florida",
    id: "L114209",
    group: 1,
  },
  {
    name: "Georgia",
    id: "220002",
    group: 1,
  },
  {
    name: "Hawaii",
    id: "540229",
    group: 1,
  },
  {
    name: "Idaho",
    id: "867650",
    group: 1,
  },
  {
    name: "Illinois",
    id: "3001420574",
    group: 1,
  },
  {
    name: "Indiana",
    id: "3703330",
    group: 1,
  },
  {
    name: "Iowa",
    id: "3001420526",
    group: 1,
  },
  {
    name: "Kansas",
    id: "870877240",
    group: 1,
  },
  {
    name: "Kentucky",
    id: "DOI-1148353",
    group: 1,
  },
  {
    name: "Louisiana",
    id: "924391",
    group: 1,
  },
  {
    name: "Maine",
    id: "AGN390155",
    group: 1,
  },
  {
    name: "Maryland",
    id: "3001420578",
    group: 1,
  },
  {
    name: "Massachusetts",
    id: "2156315",
    group: 1,
  },
  {
    name: "Michigan",
    id: "0133989",
    group: 1,
  },
  {
    name: "Minnesota",
    id: "40754943",
    group: 1,
  },
  {
    name: "Mississippi",
    id: "15041397",
    group: 1,
  },
  {
    name: "Missouri",
    id: "3001424302",
    group: 1,
  },
  {
    name: "Montana",
    id: "3001420579",
    group: 2,
  },
  {
    name: "Nebraska",
    id: "3001420519",
    group: 2,
  },
  {
    name: "Nevada",
    id: "3702599",
    group: 2,
  },
  {
    name: "New Hampshire",
    id: "3001420520",
    group: 2,
  },
  {
    name: "New Jersey",
    id: "3001420518",
    group: 2,
  },
  {
    name: "New Mexico",
    id: "3001426754",
    group: 2,
  },
  {
    name: "New York",
    id: "BR-1676822/PC-1676822",
    group: 2,
  },
  {
    name: "North Carolina",
    id: "3001420530",
    group: 2,
  },
  {
    name: "North Dakota",
    id: "3001420581",
    group: 2,
  },
  {
    name: "Ohio",
    id: "1380192",
    group: 2,
  },
  {
    name: "Oklahoma",
    id: "3001420508",
    group: 2,
  },
  {
    name: "Oregon",
    id: "3001428490",
    group: 2,
  },
  {
    name: "Pennsylvania",
    id: "1019420",
    group: 2,
  },
  {
    name: "Rhode Island",
    id: "3001420515",
    group: 2,
  },
  {
    name: "South Carolina",
    id: "3001420475",
    group: 2,
  },
  {
    name: "South Dakota",
    id: "10028566",
    group: 2,
  },
  {
    name: "Tennessee",
    id: "3001420529",
    group: 2,
  },
  {
    name: "Texas",
    id: "2705916",
    group: 2,
  },
  {
    name: "Utah",
    id: "867701",
    group: 2,
  },
  {
    name: "Vermont",
    id: "3702854",
    group: 2,
  },
  {
    name: "Virginia",
    id: "151884",
    group: 2,
  },
  {
    name: "Washington",
    id: "1113770",
    group: 2,
  },
  {
    name: "West Virginia",
    id: "3001420820",
    group: 2,
  },
  {
    name: "Wisconsin",
    id: "3001420516",
    group: 2,
  },
  {
    name: "Wyoming",
    id: "473355",
    group: 2,
  },
];

export default states;
