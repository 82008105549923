import "css/meetOurPartners.css";

import {
  PROVIDER_NATIONWIDE,
  PROVIDER_PUMPKIN,
  PROVIDER_TRUPANION,
} from "js/components/planSummary";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import React from "react";
import Typography from "@mui/material/Typography";
import Section from "js/components/section";
import { useNavigation } from "js/services/hooks";

const MeetOurPartners = () => {
  const navigateTo = useNavigation();

  const providers = [
    {
      name: PROVIDER_PUMPKIN,
      logo: "logo_pumpkin.svg",
      id: "about-insurance-pumpkin-cta",
      comprehensive: true,
    },
    {
      name: PROVIDER_TRUPANION,
      logo: "logo_trupanion.svg",
      id: "about-insurance-trupanion-cta",
    },
    {
      name: PROVIDER_NATIONWIDE,
      logo: "logo_nationwide.svg",
      id: "about-insurance-nationwide-cta",
    },
  ];

  const providerComponents = providers.map((provider, index) => {
    return (
      <React.Fragment key={index}>
        <Grid
          container
          item
          xs={12}
          md={3}
          direction="row"
          alignItems="center"
          className="sectionContainer"
          id={provider.id}
          onClick={() => {
            handleCtaClick(provider.name);
          }}
        >
          <Grid item xs={6} md={10}>
            <img
              src={`${process.env.PUBLIC_URL}/img/${provider.logo}`}
              alt={`${provider.name} logo`}
              className="logo"
            />
          </Grid>

          <Grid item xs={5} md={1}>
            {provider.comprehensive && (
              <div className="mostComprehensive">Recommended</div>
            )}
          </Grid>
          <Grid item xs={1} md={1} className="ctaArrowContainer">
            <img
              src={`${process.env.PUBLIC_URL}/img/cta-yellow-arrow.svg`}
              alt="pumpkin call to action"
              className="ctaArrow"
            />
          </Grid>
        </Grid>
        <div className={`spacer spacer-${index}`} />
      </React.Fragment>
    );
  });

  const handleCtaClick = (provider) => {
    navigateTo(`/partners#${provider}`);
  };

  return (
    <Section className="meetOurPartners">
      <Grid container className="enclosure">
        <Grid item xs={12} md={2}>
          <Typography variant="subtitle1" component="h1" className="title">
            Compare plans
          </Typography>
        </Grid>
        <Box sx={{ flexGrow: 1 }} />

        {providerComponents}

        <Grid item xs={1} md={1} />
      </Grid>
    </Section>
  );
};

export default MeetOurPartners;
