import "../../css/planComparison.css";

import PlanComparisonData from "../services/data/planComparison";
import React from "react";
import Typography from "@mui/material/Typography";
import Section from "./section";

const ComparisonDisclosure = (
  <div className="planComparison-disclaimer">
    <Typography variant="caption">
      Embark Pet Insurance Services may receive a commission in connection with
      the sale of insurance to you through Pumpkin, but not from Trupanion or
      Nationwide. Comparison information is provided using publicly available
      information as of 3/7/22 and is only meant to summarize program features,
      not a specific plan. Review the provider's plan terms for more details.
      The description of the providers' plans are not provided by that company.
      If you have questions about other plans, please contact an agent of that
      company. It is our intention to provide fair and accurate comparison
      information. We attempt to keep the information up to date but it may
      change from time to time. If you are aware of any inaccuracies or changes
      in the information provided, let us know by emailing{" "}
      <a href="mailto:howdy@embarkvet.com">howdy@embarkvet.com</a>.
    </Typography>
  </div>
);

export const PlanComparison = () => {
  return (
    <Section className="plan-comparison">
      <Typography variant="h2" className="planComparison-title">
        Compare Plans
      </Typography>
      <div className="planComparison-container">
        <table className="planComparison-tableContainer">
          <tbody>
            <tr>
              <th className="planComparison-providerHeader">Providers</th>
              <th className="planComparison-companyHeader">
                <img
                  src={`${process.env.PUBLIC_URL}/img/logo_pumpkin.svg`}
                  alt="pumpkin logo"
                  className="planComparison-companyImage desktop-only"
                />
                <img
                  src={`${process.env.PUBLIC_URL}/img/logo_pumpkin_mobile.svg`}
                  alt="pumpkin logo"
                  className="planComparison-companyImage mobile-only"
                />
              </th>
              <th className="planComparison-companyHeader">
                <img
                  src={`${process.env.PUBLIC_URL}/img/logo_trupanion.svg`}
                  alt="trupanion logo"
                  className="planComparison-companyImage desktop-only"
                />
                <img
                  src={`${process.env.PUBLIC_URL}/img/logo_trupanion_mobile.svg`}
                  alt="trupanion logo"
                  className="planComparison-companyImage trupanion-mobile mobile-only"
                />
              </th>
              <th className="planComparison-companyHeader">
                <img
                  src={`${process.env.PUBLIC_URL}/img/logo_nationwide.svg`}
                  alt="nationwide logo"
                  className="planComparison-companyImage desktop-only"
                />
                <img
                  src={`${process.env.PUBLIC_URL}/img/logo_nationwide_mobile.svg`}
                  alt="nationwide logo"
                  className="planComparison-companyImage nationwide-mobile mobile-only"
                />
              </th>
            </tr>
            {PlanComparisonData.map((item, index) => (
              <tr key={index} className="planComparison-rowSectionContainer">
                <td className="planComparison-rowSection planComparison-item-mobile">
                  {item.keyPoint}
                </td>
                <td className="planComparison-rowSection planComparison-item-mobile">
                  {item.pumpkin}
                </td>
                <td className="planComparison-rowSection planComparison-item-mobile">
                  {item.trupanion}
                </td>
                <td className="planComparison-rowSection planComparison-item-mobile">
                  {item.nationwide}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {ComparisonDisclosure}
    </Section>
  );
};

export default PlanComparison;
