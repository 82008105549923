import "../../css/ctaButton.css";

import { Config, getConfig } from "../services/config";

import React from "react";
import URLSearchParams from "core-js-pure/stable/url-search-params";
import { useLocation } from "react-router";

export const PumpkinCtaButton = ({ petName, additionalClasses, ...props }) => {
  const queryParams = new URLSearchParams(useLocation().search);
  const pumpkinDomain = getConfig(
    Config.PUMPKIN_BASE_URL,
    "https://embark.pumpkin.care"
  );
  const handleClick = () => {
    window.location.href = `${pumpkinDomain}/?utm_source=embark&inbound=${queryParams.get(
      "inbound"
    )}`;
  };
  return (
    <CtaButton
      onClick={handleClick}
      id="explore-plans-pumpkin-cta"
      additionalClasses={additionalClasses}
      {...props}
    >
      Fetch {petName}'s free quote
    </CtaButton>
  );
};

export const CtaButtonLink = ({ url, id, additionalClasses, ...props }) => {
  const handleClick = () => {
    window.location.href = url;
  };
  return (
    <CtaButton
      onClick={handleClick}
      id={id}
      additionalClasses={additionalClasses}
      {...props}
    >
      See quotes
    </CtaButton>
  );
};

export const CtaButton = ({
  onClick,
  id,
  additionalClasses,
  children,
  ...props
}) => (
  <button
    className={"cta-button " + additionalClasses}
    onClick={onClick}
    id={id}
    {...props}
  >
    {children}
  </button>
);

export default CtaButton;
