export const Heap = {
  CATEGORIES: {
    PET_INSURANCE: "Pet Insurance",
  },
  ACTIONS: {
    PUMPKIN_QUOTE: "Pumpkin Quote",
    TRUPANION_QUOTE: "Trupanion Quote",
    NATIONWIDE_QUOTE: "Nationwide Quote",
  },
};
