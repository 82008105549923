export const Config = {
  // NOTE: These should all start with `REACT_APP_*`
  PUMPKIN_BASE_URL: "REACT_APP_PUMPKIN_BASE_URL",
  CURRENT_ENV: "REACT_APP_CURRENT_ENV",
  SENTRY_DSN: "REACT_APP_SENTRY_DSN",
  HEAP_APP_ID: "REACT_APP_HEAP_APP_ID",
};

export const getConfig = (key, defaultValue) => {
  if (key in process.env) {
    return process.env[key];
  }
  if (typeof defaultValue !== "undefined") {
    return defaultValue;
  }
  throw new Error(`Missing required environment variable ${key}`);
};
