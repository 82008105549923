import { useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { TAB_PET_INSURANCE, TAB_PROVIDERS } from "../components/insurancePage";
import { PATH_INSURANCE, PATH_PROVIDERS } from "js/App";

export const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState((state) => !state), []);
  return [state, toggle];
};

export const useNavigation = () => {
  const history = useHistory();
  const { search } = useLocation();
  const navigateTo = useCallback(
    (path) => {
      const base = process.env.PUBLIC_URL
        ? process.env.PUBLIC_URL
        : "http://localhost:3001";
      const url = new URL(path, base);
      history.push(url.pathname + search + url.hash);
    },
    [history, search]
  );
  return navigateTo;
};

export const useTabChanger = () => {
  const navigateTo = useNavigation();
  const tabChange = useCallback(
    (tab) => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      const path =
        {
          [TAB_PET_INSURANCE]: PATH_INSURANCE,
          [TAB_PROVIDERS]: PATH_PROVIDERS,
        }[tab] || PATH_INSURANCE;
      navigateTo(path);
    },
    [navigateTo]
  );
  return tabChange;
};
