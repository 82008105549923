import { CheckMarkCircle, XCircle } from "../icons";

import React from "react";

export const PlanComparisonData = [
  {
    keyPoint: "Maximum enroll age",
    pumpkin: "No limit",
    trupanion: "14 years",
    nationwide: "10 years for some plans",
  },
  {
    keyPoint:
      "Money back guarnatee (if no claims have been covered; available in most states)",
    pumpkin: "30 days",
    trupanion: "30 days",
    nationwide: "10 days",
  },
  {
    keyPoint: "Visit any licensed vet in the US",
    pumpkin: <CheckMarkCircle />,
    trupanion: <CheckMarkCircle />,
    nationwide: <CheckMarkCircle />,
  },
  {
    keyPoint: "90% cash back on covered vet bills",
    pumpkin: <CheckMarkCircle />,
    trupanion: <CheckMarkCircle />,
    nationwide: "Depends on the plan",
  },
  {
    keyPoint:
      "Optional wellness package add-on covering vet-recommended care essentials",
    pumpkin: <CheckMarkCircle />,
    trupanion: <XCircle />,
    nationwide: <CheckMarkCircle />,
  },
  {
    keyPoint: "Covers hereditary conditions",
    pumpkin: <CheckMarkCircle />,
    trupanion: <CheckMarkCircle />,
    nationwide: "Depends on the plan",
  },
  {
    keyPoint: "Covers sick visit exam fees & exam fees for accidents",
    pumpkin: <CheckMarkCircle />,
    trupanion: <XCircle />,
    nationwide: <CheckMarkCircle />,
  },
  {
    keyPoint: "Alternative therapies",
    pumpkin: <CheckMarkCircle />,
    trupanion: "Optional add-on",
    nationwide: <CheckMarkCircle />,
  },
  {
    keyPoint: "Behavioral issues",
    pumpkin: <CheckMarkCircle />,
    trupanion: "Optional add-on",
    nationwide: "Depends on the plan",
  },
];

export default PlanComparisonData;
