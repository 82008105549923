import "../../css/section.css";

import React from "react";

import Box from "@mui/material/Box";

export const Section = ({ children, noGutters, ...props }) => (
  <Box component="section" {...props}>
    <Box className={`content ${noGutters ? "no-gutter" : ""}`}>{children}</Box>
  </Box>
);

export default Section;
