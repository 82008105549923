import "../../css/bannerSection.css";

import Box from "@mui/material/Box";
import CtaButton from "./ctaButton";
import React from "react";
import Section from "./section";
import { TAB_PROVIDERS } from "./insurancePage";
import Typography from "@mui/material/Typography";
import { useTabChanger } from "js/services/hooks";

export const SECTION_AVOID_VET_BILL = "avoid_vet_bills";
export const SECTION_PREPARE_FOR_VET = "prepare_for_vet";

const data = {
  [SECTION_AVOID_VET_BILL]: {
    variant: "avoid-vet-bills",
    header: "Help for paying surprise, covered vet bills.",
    text: "These pet insurance providers cover eligible care by veterinary practices nationwide.",
    cta: "Explore insurance plans",
  },
  [SECTION_PREPARE_FOR_VET]: {
    variant: "prepare-for-vet",
    header: "Prepare for vet visits before they happen",
    text: "Imagine being able to make the best health care decisions for your dog knowing that the eligible treatment is covered.",
    cta: "Cover your pet",
  },
};

const PumpkinLogoWhite = () => (
  <Box className="logo pumpkin">
    <img
      src={`${process.env.PUBLIC_URL}/img/logo_pumpkin_white.svg`}
      alt="white pumpkin logo"
    />
  </Box>
);
const TrupanionLogoWhite = () => (
  <Box className="logo trupanion">
    <img
      src={`${process.env.PUBLIC_URL}/img/logo_trupanion_white.svg`}
      alt="white trupanion logo"
    />
  </Box>
);
const NationwideLogoWhite = () => (
  <Box className="logo nationwide">
    <img
      src={`${process.env.PUBLIC_URL}/img/logo_nationwide_white.svg`}
      alt="white nation wide logo"
    />
  </Box>
);

const BannerSectionVariant = ({ variant, header, text, cta }) => {
  const changeTab = useTabChanger();
  return (
    <Section className="banner-section" noGutters>
      <Box className={`${variant}`}>
        <Typography variant="h3">{header}</Typography>
        <Box className="subtext-wrapper">
          <Typography variant="subtitle1">{text}</Typography>
        </Box>
        <Box className="background-fade">
          <CtaButton
            onClick={() => {
              changeTab(TAB_PROVIDERS);
            }}
          >
            {cta}
          </CtaButton>
          <Box className="logos">
            <PumpkinLogoWhite />
            <TrupanionLogoWhite />
            <NationwideLogoWhite />
          </Box>
        </Box>
      </Box>
    </Section>
  );
};

export const BannerSection = ({ variant }) => (
  <BannerSectionVariant variant={variant} {...data[variant]} />
);
