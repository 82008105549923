import IntroHero from "../../components/introHero";
import MeetOurPartners from "../meetOurPartners";
import AboutPetInsurance from "../aboutPetInsurance";
import LifetimeCareCost from "../lifetimeCareCost";
import PetInsuranceCost from "../petInsuranceCost";
import HowDoesItWork from "../howDoesItWork";
import React from "react";
import TopReasons from "../../components/topReasons";
import {
  BannerSection,
  SECTION_AVOID_VET_BILL,
  SECTION_PREPARE_FOR_VET,
} from "../bannerSection";

const PetInsurance = () => {
  return (
    <>
      <IntroHero />
      <MeetOurPartners />
      <AboutPetInsurance />
      <TopReasons />
      <BannerSection variant={SECTION_AVOID_VET_BILL} />
      <LifetimeCareCost />
      <PetInsuranceCost />
      <HowDoesItWork />
      <BannerSection variant={SECTION_PREPARE_FOR_VET} />
    </>
  );
};

export default PetInsurance;
