import "../css/App.css";

import { Config, getConfig } from "./services/config";
import InsurancePage, {
  TAB_PET_INSURANCE,
  TAB_PROVIDERS,
} from "./components/insurancePage";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";

import AnchorScrollHandler from "js/components/anchorScrollHandler";
import React from "react";

export const PATH_INSURANCE = "/";
export const PATH_PROVIDERS = "/partners";

const App = () => {
  const currentEnv = getConfig(Config.CURRENT_ENV, "unknown");
  const theme = createTheme({
    typography: {
      fontFamily: ["Gilroy"],
    },
    breakpoints: {
      keys: ["xs", "sm", "md"],
      values: { xs: 0, sm: 600, md: 1440 },
    },
  });
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <AnchorScrollHandler />
          <Switch>
            <Route exact path={PATH_PROVIDERS}>
              <InsurancePage activeTab={TAB_PROVIDERS} />
            </Route>
            <Route>
              <InsurancePage activeTab={TAB_PET_INSURANCE} />
            </Route>
          </Switch>
          <div hidden={true} id={`${currentEnv}`}></div>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
