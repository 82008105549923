import "./css/App.css";

import * as Sentry from "@sentry/react";

import App from "./js/App";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import { Config, getConfig } from "js/services/config";

const sentryDsn = getConfig(Config.SENTRY_DSN, "");

const currentEnv = getConfig(Config.CURRENT_ENV, "unknown");
Sentry.init({
  dsn: sentryDsn,
  integrations: [new Integrations.BrowserTracing()],
  environment: currentEnv,
  tracesSampleRate: 0.01,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
