import "../../css/petInsuranceCost.css";

import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Section from "./section";
import Image from "js/components/image";

const PetInsuranceCost = () => (
  <Section>
    <Grid container justifyContent="space-between" className="insurance-cost">
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle1">
          How much does pet insurance cost?
        </Typography>
        <Typography variant="body1" component="p">
          Cost of pet insurance is the biggest concern we've heard from Embark
          customers. Remember, it may be cheaper to have insurance and not need
          it than to pay for an unexpected vet bill out of pocket.
        </Typography>
        <Typography className="body-disclaimer">
          Costs are dependent on your dog's profile, the type of coverage you
          want, and the plan options you choose.
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} className="average-cost">
        <Typography className="cost">$49.51</Typography>
        <Typography className="cost-disclaimer">average per month*</Typography>
        <Typography className="source">
          North American Pet Health Insurance Association
        </Typography>
        <Image
          src={`${process.env.PUBLIC_URL}/img/yorkshire-sitting.png`}
          alt="sitting yorkshire"
        />
      </Grid>
    </Grid>
  </Section>
);

export default PetInsuranceCost;
