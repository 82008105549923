import "../../css/faq.css";

import Collapse from "@mui/material/Collapse";
import { ExpandMore } from "../services/icons";
import Grid from "@mui/material/Grid";
import React from "react";
import { Rotate180 } from "./animate";
import Section from "./section";
import Typography from "@mui/material/Typography";
import questionAndAnswers from "../services/data/faq";
import { useToggle } from "../services/hooks";

const FAQCollapsible = ({ question, answers }) => {
  const [expanded, toggleExpanded] = useToggle(false);
  return (
    <div className={`collapsible ${expanded ? "open" : "closed"}`}>
      <div onClick={toggleExpanded} className="trigger">
        <div>{question}</div>
        <Rotate180 in={expanded}>
          <ExpandMore />
        </Rotate180>
      </div>
      <Collapse in={expanded}>
        {answers.map((answer, index) => (
          <p key={index} className="answer">
            {answer}
          </p>
        ))}
      </Collapse>
    </div>
  );
};

export const FAQ = () => (
  <Section className="faq">
    <Typography variant="h2">
      Frequently asked questions about pet insurance
    </Typography>
    <Typography paragraph className="intro">
      Let’s break down what you need to know and what you should consider when
      finding the right pet insurance plan for your family.
    </Typography>
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      className="questions"
    >
      <Grid item xs={12} md={6}>
        {questionAndAnswers
          .filter((_, idx, arr) => idx < arr.length / 2)
          .map((questionAndAnswer, index) => (
            <FAQCollapsible
              key={index}
              question={questionAndAnswer.question}
              answers={questionAndAnswer.answers}
            />
          ))}
      </Grid>
      <Grid item xs={12} md={6}>
        {questionAndAnswers
          .filter((_, idx, arr) => idx >= arr.length / 2)
          .map((questionAndAnswer, index) => (
            <FAQCollapsible
              key={index}
              question={questionAndAnswer.question}
              answers={questionAndAnswer.answers}
            />
          ))}
      </Grid>
    </Grid>
  </Section>
);

export default FAQ;
