import "../../css/introHero.css";

import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Section from "./section";

const IntroHero = () => {
  return (
    <Section className="aboutPetInsuranceHero-layout" noGutters>
      <img
        src={`${process.env.PUBLIC_URL}/img/epis-logo-white.svg`}
        className="aboutPetInsuranceHero-episLogo"
        alt="Embark pet insurance services logo"
      />
      <Grid container className="aboutPetInsuranceHero-container">
        <Grid
          item
          xs={12}
          md={6}
          className="aboutPetInsuranceHero-textContainer"
        >
          <Typography
            variant="h3"
            component="h1"
            className="aboutPetInsuranceHero-title"
          >
            Fetch your pawfect plan
          </Typography>
          <Typography
            variant="subtitle1"
            component="h2"
            className="aboutPetInsuranceHero-subtitle"
          >
            Get help protecting your dog from unexpected illnesses or accidents
            with pet insurance.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className="aboutPetInsuranceHero-image-wrap">
          <Box className="aboutPetInsuranceHero-image"></Box>
        </Grid>
      </Grid>
    </Section>
  );
};

export default IntroHero;
