import React from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export const Image = ({ src, ...props }) => {
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"));
  const _src = src.replace(".png", isSmall ? "-sm.png" : ".png");
  return <img {...props} src={_src} />;
};

export default Image;
