import "../../../css/ourPartners.css";

import React from "react";
import Box from "@mui/material/Box";

import PlanSummaries from "../planSummaries";
import PlanComparison from "../planComparison";

const OurPartners = () => {
  return (
    <Box className="our-partners">
      <PlanSummaries />
      <PlanComparison />
    </Box>
  );
};

export default OurPartners;
