import Grid from "@mui/material/Grid";
import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import "css/disclaimers.css";
import { LicenseInfoModal } from "js/components/disclaimers/licenseInfoModal";
import { useToggle } from "js/services/hooks";
import { Section } from "../section";

export const DisclaimersContainer = () => {
  const [modalIsDisplayed, toggleModal] = useToggle();
  return (
    <Section className="disclaimers-container">
      <Grid container spacing={1} className="disclaimers-grid">
        <Grid item xs={12} md={6}>
          <Typography>
            Embark Pet Insurance Services Inc. (“Embark Agency”) is a licensed
            insurance agency (NPN19084749) distributing products on behalf of
            Pumpkin Pet Insurance Inc. (“Pumpkin”) (NPN19084749), which is a
            licensed insurance agency, not an insurer.
            <br />
            <br />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography>
            Insurance products underwritten by Independence American Insurance
            Company (NAIC #2658; Scottsdale, AZ), or United States Fire
            Insurance Company (NAIC #21113; Morristown, NJ); and are
            administered & produced by Pumpkin Insurance Services Inc.
            (“Pumpkin”) (NPN #19084749; New York, NY; CA License #6001617).
          </Typography>
          <br />
          <Link
            className="disclaimers-licenseInfo-link"
            onClick={() => {
              toggleModal();
            }}
          >
            For more details see here
          </Link>
        </Grid>
      </Grid>
      <LicenseInfoModal
        isDisplayed={modalIsDisplayed}
        toggleModal={toggleModal}
      />
    </Section>
  );
};
