import "../../css/aboutPetInsurance.css";

import Grid from "@mui/material/Grid";
import React from "react";
import Section from "./section";
import Typography from "@mui/material/Typography";
import Image from "js/components/image";

const AboutPetInsurance = () => {
  const items = [
    {
      image: "illustration_heart.png",
      title: "Healthy pets",
      description:
        "Unexpected vet visits happen. With an extensive pet insurance plan, you have help avoiding budget shocks and making the best health care decisions for your dog.",
    },
    {
      image: "illustration_plus.png",
      title: "Is pet insurance worth it?",
      description:
        "Hopefully, your dog will never experience a serious illness or injury. If they do, pet insurance helps you focus on getting the care your pet needs with less worry about the cost.",
    },
  ];

  const aboutPetInsuranceDetails = items.map((item, index) => {
    return (
      <Grid
        key={index}
        container
        className="aboutPetInsurance-benefitsContainer"
      >
        <Grid item xs={12} md={1} />
        <Grid
          item
          xs={12}
          md={5}
          className="aboutPetInsurance-benefitsImageContainer"
        >
          <Image
            src={`${process.env.PUBLIC_URL}/img/${item.image}`}
            className="aboutPetInsurance-benefitsImage"
            alt={item.image}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          className="aboutPetInsurance-benefitsTextContainer"
        >
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            className="base-header-font padding-top-medium aboutPetInsuranceMainText-header2"
          >
            {item.title}
          </Typography>
          <Typography
            variant="body1"
            component="p"
            gutterBottom
            className="base-paragraph-font aboutPetInsuranceMainText-description"
          >
            {item.description}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2} />
      </Grid>
    );
  });

  return (
    <Section className="aboutPetInsuranceMainText-container" noGutters>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        className="base-header-font padding-top-large aboutPetInsuranceMainText-header1"
      >
        About Pet Insurance
      </Typography>
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        className="base-paragraph-font aboutPetInsuranceMainText-mainDescription"
      >
        We know choosing the right pet insurance can be hard, so we've done the
        digging for you!
      </Typography>

      {aboutPetInsuranceDetails}
    </Section>
  );
};

export default AboutPetInsurance;
