import "css/navigation.css";

import React from "react";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Section from "./section";

import { TAB_PET_INSURANCE, TAB_PROVIDERS } from "js/components/insurancePage";

export const NavBar = ({ activeTab, onTabChange }) => (
  <AppBar color="inherit" position="sticky">
    <Section className="site-nav-section">
      <Toolbar>
        <img
          src={`${process.env.PUBLIC_URL}/img/epis_logo.svg`}
          alt="placeholder"
          className="header-image"
          onClick={() => {
            onTabChange(TAB_PET_INSURANCE);
          }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <TabContext value={activeTab}>
          <Tabs
            selectionFollowsFocus={true}
            id="tabs"
            variant="fullWidth"
            className="tabs"
            onChange={(e, tab) => {
              onTabChange(tab);
            }}
            value={activeTab}
          >
            <Tab label="About insurance" value={TAB_PET_INSURANCE} />
            <Tab label="" icon={<Divider />} disabled className="divider" />
            <Tab
              className="explore-plans"
              label="Explore plans"
              value={TAB_PROVIDERS}
              icon={
                <img
                  src={`${process.env.PUBLIC_URL}/img/cta-yellow-arrow.svg`}
                  alt="explore plans"
                  className="mainPage-tabPanel-ctaArrow"
                />
              }
              iconPosition="end"
            />
          </Tabs>
        </TabContext>
      </Toolbar>
    </Section>
  </AppBar>
);

export default NavBar;
